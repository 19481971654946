import React from "react";
import img from '../assets/img/lety-wallpaper.jpg'
import lety from '../assets/img/lety-logo.svg'

class ComingSoon extends React.Component {
    render(){
        return(
            <section className="cs_coming-soon" style={{
                backgroundImage: `url(${img})`
            }}>
                <div className="cs_background-msg"><div>lifestyle<br />living</div></div>
                <div className="cs_inner">
                    <img className="cs_img" src={lety} alt="" style={{marginBottom: '60px'}} />
                    <p className="cs_par"><i>the new lëty lifestyle living website is</i></p>
                    <h1 className="cs_heading">COMING SOON!</h1>
                    <div className="cs_vline" />
                    <p className="cs_par" style={{marginBottom: '30px'}}>
                        <a href="tel:+33662556642">+33 (0)6 62 55 66 42</a> <br />
                        <a href="mailto:aisha@letylifestyleliving.com">aisha@letylifestyleliving.com</a>
                    </p>
                    <h2 className="cs_sub-heading" style={{marginBottom: '30px'}}>Aisha Moussa Limmer</h2>
                    <p className="cs_par">
                        <a href="https://goo.gl/maps/rJnRynL6NQpSGQQi8" target="_blank" rel="noreferrer">21 rue du Vieil Abreuvoir 78100 Saint-Germain-en-Laye</a>
                    </p>
                </div>
            </section>
        )
    }
}

export default ComingSoon